import React, { useRef } from "react";
import Icon from "../../icon-svg/icon";
import inputError from "../../../assets/images/input-error.svg";
import "./input.scss";

const Input = ({
    className = "",
    isValid = true,
    isValidOther = true,
    type,
    name,
    value = "",
    maxLength,
    onChange,
    disabled = false,
    placeholder,
    icon,
    forwardedRef,
    onClick = undefined,
    onFocus = () => {}
}) => {
    const inputRef = useRef();
    return (
        <div className={`carbon-form__input ${className}`} ref={inputRef}>
            <input
                type={type}
                name={name}
                value={value}
                maxLength={maxLength}
                onChange={onChange}
                disabled={disabled}
                placeholder={placeholder}
                autoComplete="off"
                ref={forwardedRef}
                onClick={onClick}
                onFocus={onFocus}
            />
            {(!isValid || !isValidOther) && (
                <div className="carbon-form__input-icon">
                    <img src={inputError} alt="input not valid" />
                </div>
            )}
            {icon && (
                <div className="carbon-form__valid-input-icon">
                    <Icon name={icon} />
                </div>
            )}
        </div>
    );
};

export default Input;
