import React from "react";
import Footer from "./footer/footer";
import "./page-wrapper.scss";

const PageWrapper = (props) => {
    const { className = "", children } = props;
    return (
        <div className="page-wrapper">
            <div className={`page-wrapper__content ${className}`}>{children}</div>
            <Footer className="page-wrapper__footer" />
        </div>
    );
};

export default PageWrapper;
