import React, { createContext, useState } from "react";

const RouterContext = createContext({});

const RouterProvider = ({ children }) => {
    const [validated, setValidated] = useState(false);
    return (
        <RouterContext.Provider
            value={{
                validated,
                setValidated
            }}
        >
            {children}{" "}
        </RouterContext.Provider>
    );
};

export { RouterProvider, RouterContext };
