import React, { createContext, useState } from "react";

export const ErrorContext = createContext();

const ErrorProvider = ({ children }) => {
    const [errorPage, setErrorPage] = useState("");
    const [titlePage, setTitlePage] = useState("");
    const [showMessagePage, setShowMessagePage] = useState("");
    const [showTitleBtnPage, setShowTitleBtnPage] = useState("");
    return (
        <ErrorContext.Provider
            value={{
                errorPage,
                setErrorPage,
                titlePage,
                setTitlePage,
                showMessagePage,
                setShowMessagePage,
                showTitleBtnPage,
                setShowTitleBtnPage
            }}
        >
            {children}{" "}
        </ErrorContext.Provider>
    );
};

export default ErrorProvider;
