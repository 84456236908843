import React, { useContext, useState } from "react";
import "./tiers-select.scss";
import { useTranslation } from "react-i18next";
import logo from "../../../../shared/assets/images/logo_sage.svg";
import Tiers from "../tiers/tiers";
import { InterlocContext } from "../../../../context/interlocContext";
import LoadingShared from "../../../../shared/components/loading/loading";
/**
 * Tiers select component used to render table with differents tiers.
 */
const TiersSelect = () => {
    const { t } = useTranslation();
    const { tier } = useContext(InterlocContext);
    const [isLoading, setIsLoading] = useState(false);

    if (isLoading) return <LoadingShared />;

    return (
        <div className="tiers-select">
            <div className="attachment-form__header">
                <img src={logo} className="attachment-form__logo" alt={t("title")} />{" "}
                <div className="attachment-form__title">
                    <p> {t("authentication:authenticationForm.sageConnection")} </p>
                </div>
            </div>
            <hr />
            <div className="tiers-select__title">
                <p> {t("authentication:select-tiers.title")} </p>
            </div>
            <div className="tiers-select__tiers-list">
                {tier.map((tiers) => (
                    <Tiers tiers={tiers} setIsLoading={setIsLoading} />
                ))}
            </div>
        </div>
    );
};

export default TiersSelect;
