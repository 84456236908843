import i18next from "../../config/i18n/index";
import i18nAuthentication from "./i18n/fr.json";
import AuthenticationForm from "./components/authentication-form/authentication-form";
import Container from "./components/container/container";

i18next.addResourceBundle("fr", "authentication", i18nAuthentication);

const Authentication = { AuthenticationForm, Container };

export default Authentication;
