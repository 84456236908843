import React from "react";
import TiersElement from "./tiers-element";

const Tiers = ({ tiers, setIsLoading }) => (
    <div className="tiers-select__tiers-list">
        <div className="tiers-select__tiers-list-item">
            <TiersElement tiers={tiers.parent} className="tiers-element" setIsLoading={setIsLoading} />
        </div>
        {tiers.filials && tiers.filials.map((tierr) => <TiersElement tiers={tierr} className="tiers-sub-element" setIsLoading={setIsLoading} />)}
    </div>
);
export default Tiers;
