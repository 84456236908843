import React, { useContext } from "react";
import "./server-error.scss";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ErrorContext } from "../../../../context/errorContext";
import FormElements from "../../../../shared/components/form-elements";
import errorImage from "../../../../shared/assets/images/bitmap.png";
import otherErrorImage from "../../../../shared/assets/images/bitmaperror.png";
import Icon from "../../../../shared/components/icon-svg/icon";

const { REACT_APP_DOCU_SIGN } = process.env;

/**
 * Server error component used to render the appropriate error message.
 */
const ServerError = () => {
    const { errorPage, titlePage, showMessagePage, showTitleBtnPage } = useContext(ErrorContext);
    const history = useHistory();
    const { t } = useTranslation();
    const redirectToLoading = () => {
        history.push("/loading");
    };
    return (
        <div className="server-error">
            <div className="server-error__image">
                <img src={errorPage && errorPage !== "" ? otherErrorImage : errorImage} alt={t("title")} />{" "}
            </div>
            <div className="server-error__text">
                <p className="server-error__error">{errorPage && errorPage !== "" ? errorPage : t("authentication:server-error.error")}</p>
                <div className="server-error__title">
                    <p>{titlePage && titlePage !== "" ? titlePage : t("authentication:server-error.title")}</p>
                </div>
                <div className="server-error__message">
                    <p>{showMessagePage && showMessagePage !== "" ? showMessagePage : t("authentication:server-error.message")}</p>
                </div>
                <div className={`server-error__link${errorPage && errorPage === "DUE DILIGENCE INVALIDE" ? "_due_diligence" : ""}`}>
                    {errorPage && errorPage === "TIERS BLOQUE" && <a href="mailto:contentieux.clients@sage.com"> {showTitleBtnPage} </a>}
                    {errorPage && errorPage === "DUE DILIGENCE INVALIDE" && <a href="mailto:duediligencefrance@sage.com"> {showTitleBtnPage}</a>}
                    {!errorPage && (
                        <FormElements.Button
                            type="button"
                            className="button button--large button--primary"
                            onClick={() => {
                                redirectToLoading();
                            }}
                        >
                            <p>{t("authentication:server-error.button")}</p>
                            <Icon name="actions-refresh" />
                        </FormElements.Button>
                    )}
                </div>
                {errorPage && errorPage === "DUE DILIGENCE INVALIDE" && (
                    <>
                        <div>
                            <p className="server-error__title_attention">{t("authentication:server-error.attention")}</p>
                            <p className="server-error__subtitle_attention">{t("authentication:server-error.title-attention")}</p>
                            <p className="server-error__countries_attention">{t("authentication:server-error.countries-attention")}</p>
                        </div>
                        <div className="server-error__link_sign_Doc">
                            <FormElements.Button
                                type="button"
                                className="button button--large button--primary authentication__button"
                                onClick={() => {
                                    window.open(REACT_APP_DOCU_SIGN, "_self");
                                }}
                            >
                                {t("authentication:due-diligence.button-doc")}
                            </FormElements.Button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
export default ServerError;
