import React, { useContext } from "react";
import "./disableImpersonate.scss";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import FormElements from "../../../../shared/components/form-elements";
import { AuthContext } from "../../../../context";
/**
 * Button component used for logging out (desactivating the impersonation).
 */
const DisableImpersonate = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { disableImpersonate } = useContext(AuthContext);

    const disableImpersonation = () => {
        disableImpersonate();
        history.push({ pathname: "/loading" });
    };

    return (
        <div className="disableImpers-button">
            <FormElements.Button
                type="button"
                onClick={() => {
                    disableImpersonation();
                }}
                className=" button--large  "
            >
                {t("authentication:disableImpersonate")}
            </FormElements.Button>
        </div>
    );
};

export default DisableImpersonate;
