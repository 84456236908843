import React, { useEffect, useCallback, useContext, useState } from "react";
import "./loading.scss";
import { useHistory } from "react-router-dom";
import jwt from "jsonwebtoken";
import { Base64 } from "js-base64";
import { AuthContext } from "../../../../context";
import { InterlocContext } from "../../../../context/interlocContext";
import { getByEmail, redirectToZendesk, getTiersByCustomerCode } from "../../services/interlocutor";
import loaderMessage from "../../../../shared/assets/images/new_frame.svg";
import handleError from "../../../../shared/utils/errorService";
import { ErrorContext } from "../../../../context/errorContext";
import { RouterContext } from "../../../../context/routerContext";
import checkTiersValidity from "../../../../shared/utils/tiers.validator";

/**
 * Loading component used to render the loading spinner
 */
const Loading = () => {
    const { a0Auth, a0User, a0Token, idToken } = useContext(AuthContext);
    const { setTier, setInterlocutor } = useContext(InterlocContext);
    const { setValidated } = useContext(RouterContext);
    const [tiersParams] = useState(JSON.parse(localStorage.getItem("sbc_params")) || {});
    const { setErrorPage, setTitlePage, setShowMessagePage, setShowTitleBtnPage } = useContext(ErrorContext);
    const history = useHistory();

    /**
     * Initialization of the input fields by the number of attached tiers.
     * If one, direct redirection to zendesk
     * If none, redirection to the attachment page
     * If many, redirection to the tiers select page.
     */
    const initInput = useCallback(async () => {
        const auth0Token = localStorage.getItem("a0-token") ? localStorage.getItem("a0-token") : null;
        if (Object.entries(tiersParams).length > 0 && tiersParams.tiersCode) {
            const { tiersCode } = tiersParams;
            await getTiersByCustomerCode(tiersCode)
                .then(async (resp) => {
                    const { token, interlocutor, tiers } = resp.data;
                    const decodedToken = jwt.decode(token);
                    const { tiersName = "" } = tiers;
                    const { interlocutorPivotId, tiersPivotId } = decodedToken || 0;
                    const { lastName = "", firstName = "", civility = "" } = interlocutor;
                    const tokenXauthorization = localStorage.getItem("x-authorization") ? localStorage.getItem("x-authorization") : "";

                    await redirectToZendesk(token, tokenXauthorization).then((res) => {
                        const { token: payload } = res.data;
                        const { ticketFormId, subscriptionRef, request = false, folderNumber = "" } = tiersParams;
                        const paramsUrl = {
                            ticket_form_id: ticketFormId,
                            tiers_id: tiersPivotId,
                            token: auth0Token,
                            request,
                            folder_number: folderNumber,
                            interlocutor_id: interlocutorPivotId,
                            subscription_number: subscriptionRef,
                            first_name: firstName,
                            last_name: lastName,
                            civility,
                            tiersCode,
                            raison_social: tiersName
                        };
                        const paramsUrlBase64 = Base64.encode(JSON.stringify(paramsUrl));
                        const redirectURL =
                            request && request === "false"
                                ? `${process.env.REACT_APP_ZENDESK_URL}/hc/fr/requests/new?sbc_token=${paramsUrlBase64}&tokenXauthorization=${tokenXauthorization}`
                                : `${process.env.REACT_APP_ZENDESK_URL}/hc/fr/requests/?tokenXauthorization=${tokenXauthorization}`;
                        const encodeURL = encodeURIComponent(redirectURL);
                        window.open(`${process.env.REACT_APP_ZENDESK_URL}/access/jwt?jwt=${payload}&return_to=${encodeURL}`, "_self");
                    });
                })
                .catch(async (err) => {
                    if (err.response && err.response.data && err.response.status === 403) {
                        // when tiers is bloqued
                        checkTiersValidity(
                            err.response.data.access,
                            err.response.data.criteria,
                            setErrorPage,
                            setTitlePage,
                            setShowMessagePage,
                            setShowTitleBtnPage
                        );
                        history.push("/error");
                    } else if (err.response && err.response.data && err.response.status === 404) {
                        setValidated(true);
                        history.push({ pathname: "/attachment" });
                    } else {
                        await handleError(err);
                        history.push("/error");
                    }
                });
        } else if (a0Auth && a0Token && !idToken) {
            await getByEmail()
                .then(async (resp) => {
                    const { interlocutors, token, tiers } = resp.data;

                    const decodedToken = jwt.decode(token);
                    const { interlocutorPivotId, tiersPivotId, tiers: tiersObj } = decodedToken || 0;
                    const tokenXauthorization = localStorage.getItem("x-authorization") ? localStorage.getItem("x-authorization") : "";

                    // When one  tiers is found
                    if (tiers && interlocutors.length === 1) {
                        const { tiersName = "", tiersCode = "" } = tiersObj.tiers;
                        const { lastName = "", firstName = "", civility = "" } = interlocutors[0];
                        await redirectToZendesk(token, tokenXauthorization).then((res) => {
                            const { token: payload } = res.data;
                            const paramsUrl = {
                                tiers_id: tiersPivotId,
                                token: auth0Token,
                                interlocutor_id: interlocutorPivotId,
                                first_name: firstName,
                                last_name: lastName,
                                civility,
                                raison_social: tiersName,
                                tiersCode
                            };
                            const paramsUrlBase64 = Base64.encode(JSON.stringify(paramsUrl));
                            const redirectURL = `${process.env.REACT_APP_ZENDESK_URL}/hc/fr?sbc_token=${paramsUrlBase64}&tokenXauthorization=${tokenXauthorization}`;
                            const encodeURL = encodeURIComponent(redirectURL);
                            window.open(`${process.env.REACT_APP_ZENDESK_URL}/access/jwt?jwt=${payload}&return_to=${encodeURL}`, "_self");
                        });
                    } else if (tiers && interlocutors.length > 1) {
                        // when multi codeTiers are found
                        // redirection to list tiers to choose one
                        setTier(tiers);
                        setValidated(true);
                        history.push({ pathname: "/tiers" });
                    } else {
                        // When no tiers is attached
                        // redirection to attachment form
                        setInterlocutor(interlocutors);
                        setValidated(true);
                        history.push({ pathname: "/attachment" });
                    }
                })
                .catch(async (err) => {
                    if (err.response && err.response.data && err.response.status === 403) {
                        // when tiers is bloqued
                        checkTiersValidity(
                            err.response.data.access,
                            err.response.data.criteria,
                            setErrorPage,
                            setTitlePage,
                            setShowMessagePage,
                            setShowTitleBtnPage
                        );
                        history.push("/error");
                    } else {
                        await handleError(err);
                        history.push("/error");
                    }
                });
        }
    }, [
        tiersParams,
        a0Auth,
        a0Token,
        idToken,
        setErrorPage,
        setTitlePage,
        setShowMessagePage,
        setShowTitleBtnPage,
        history,
        setTier,
        setValidated,
        setInterlocutor
    ]);

    useEffect(() => {
        if (a0Auth && a0User && !idToken && a0Token) {
            initInput();
        }
    }, [a0User, a0Auth, idToken, initInput, a0Token]);

    return (
        <div className="loading">
            <img src={loaderMessage} className="loading__message" alt="loadermessage" />
        </div>
    );
};
export default Loading;
