const setupInterceptor = (axiosInstance) => {
    axiosInstance.interceptors.request.use(
        (config) => {
            const authHeader = localStorage.getItem("a0-token") ? { authorization: `Bearer ${localStorage.getItem("a0-token")}` } : {};
            const xUserEmailHeader =
                localStorage.getItem("x-user-email") && localStorage.getItem("x-user-name") && process.env.NODE_ENV === "development"
                    ? { "x-user-email": localStorage.getItem("x-user-email") }
                    : {};
            const xUserNameHeader =
                localStorage.getItem("x-user-name") && process.env.NODE_ENV === "development" ? { "x-user-name": localStorage.getItem("x-user-name") } : {};

            const xAuthHeader = localStorage.getItem("x-authorization") ? { "x-authorization": localStorage.getItem("x-authorization") } : {};

            return {
                ...config,
                headers: {
                    ...config.headers,
                    accept: "application/json",
                    ...authHeader,
                    ...xUserEmailHeader,
                    ...xUserNameHeader,
                    ...xAuthHeader
                }
            };
        },
        (error) => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use(
        (res) => res,
        (error) => {
            if (process.env.NODE_ENV !== "production" && error.response) {
                const { id, error: errorMessage } = error.response.data;
                // eslint-disable-next-line no-console
                console.error(`Error: ${errorMessage}\n More detail about this error can be found in API log by searching the following id: "${id}"`);
            }
            return Promise.reject(error);
        }
    );
};

export default setupInterceptor;
