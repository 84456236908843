import React, { useContext, useState, useEffect } from "react";
import "./authentication-form.scss";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import FormElements from "../../../../shared/components/form-elements";
import { AuthContext } from "../../../../context";
import { RouterContext } from "../../../../context/routerContext";
import { emailRegex } from "../../../../shared/utils/regex";

import logo from "../../../../shared/assets/images/sage_logo.svg";
/**
 * Authentication component used to render the authentication form
 */
const Authentication = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { login, a0Auth } = useContext(AuthContext);

    const { setValidated } = useContext(RouterContext);
    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(false);

    useEffect(() => {
        if (a0Auth) {
            setValidated(true);
            history.push({ pathname: "/loading" });
        }
    }, [a0Auth, history, setValidated]);
    /**
     * Check wether the email is valid.
     * @param {*} event
     */
    const onEmailChange = (event) => {
        const currentEmail = event.target.value;
        setEmail(currentEmail);
        if (emailRegex.test(currentEmail)) setValidEmail(true);
        else setValidEmail(false);
    };

    return (
        <div className="authentication-form">
            <div className="authentication-form__header">
                <img src={logo} className="authentication-form__logo" alt={t("title")} />{" "}
                <div className="authentication-form__title">
                    <p> {t("authentication:authenticationForm.sageConnection")} </p>
                </div>
            </div>
            <div className="authentication-form__item">
                <label className="authentication-form__item-label">{t("authentication:authenticationForm.email")}</label>
                <FormElements.Input className="authentication-form__input" value={email} onChange={onEmailChange} />
            </div>
            <FormElements.Button
                type="button"
                className="button button--large button--primary authentication-form__button"
                onClick={() => login(email)}
                disabled={!validEmail}
            >
                {t("authentication:authenticationForm.button")}
            </FormElements.Button>
        </div>
    );
};

export default Authentication;
