import React, { useContext, useEffect } from "react";
import { Switch, Redirect, useLocation } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import queryString from "query-string";
import { AuthProvider } from "./context";
import InterlocProvider from "./context/interlocContext";
import ErrorProvider from "./context/errorContext";
import ProtectedRoute from "./context/authentification/protected-route";
import AuthenticationForm from "./domains/authentication/components/authentication-form/authentication-form";
import { RouterContext } from "./context/routerContext";
import AttachmentForm from "./domains/authentication/components/attachment-form/attachment-form";
import TiersSelect from "./domains/authentication/components/tiers-select/tiers-select";
import Loading from "./domains/authentication/components/loading/loading";
import ServerError from "./domains/authentication/components/server-error/server-error";
import ConnectAs from "./domains/authentication/components/connect-as/connect-as";
import PageWrapper from "./shared/components/page-wrapper/page-wrapper";

const auth0 = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: `${window.location.origin}/loading`,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE
};

const AppRoutes = () => {
    const { validated } = useContext(RouterContext);
    const location = useLocation();
    const decodedParams = Buffer.from(location.search, "base64").toString();
    const {
        tiers_code: tiersCode,
        subscription_number: subscriptionRef,
        ticket_form_id: ticketFormId,
        folder_number: folderNumber,
        request
    } = queryString.parse(decodedParams);

    const cleanQueryParams = (params) => Object.fromEntries(Object.entries(params).filter(([, v]) => v && v.toString().length >= 0));
    useEffect(() => {
        const params = {
            tiersCode,
            subscriptionRef,
            ticketFormId,
            folderNumber,
            request
        };

        const existing = cleanQueryParams(params);

        if (Object.keys(existing).length > 0) {
            localStorage.setItem("sbc_params", JSON.stringify(params));
        }
    }, [tiersCode, subscriptionRef, ticketFormId, folderNumber, request]);

    return (
        <PageWrapper>
            <Auth0Provider
                audience={auth0.audience}
                scope="read:token"
                domain={auth0.domain}
                clientId={auth0.clientId}
                redirectUri={auth0.redirectUri}
                cacheLocation="localstorage"
                useRefreshTokens
            >
                <ErrorProvider>
                    <InterlocProvider>
                        <AuthProvider>
                            <Switch>
                                <ProtectedRoute exact path="/" component={AuthenticationForm} />
                                <ProtectedRoute
                                    exact
                                    path="/attachment"
                                    component={() => {
                                        if (validated) {
                                            return <AttachmentForm />;
                                        }
                                        return <Redirect to="/" />;
                                    }}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/tiers"
                                    component={() => {
                                        if (validated) {
                                            return <TiersSelect />;
                                        }
                                        return <Redirect to="/" />;
                                    }}
                                />
                                <ProtectedRoute exact path="/error" component={ServerError} />
                                <ProtectedRoute exact path="/loading" component={Loading} />
                                <ProtectedRoute exact path="/impersonate" component={ConnectAs} />
                            </Switch>
                        </AuthProvider>
                    </InterlocProvider>
                </ErrorProvider>
            </Auth0Provider>
        </PageWrapper>
    );
};

export default AppRoutes;
