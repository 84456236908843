import i18n from "../../config/i18n";

const checkTiersValidity = (access, criteria, setErrorPage, setTitlePage, setShowMessagePage, setShowTitleBtnPage) => {
    if ((access === "denied" || access === "limited") && criteria === "sanctionPolicy") {
        setErrorPage(i18n.t("authentication:sanction-policy.error"));
        setTitlePage(i18n.t("authentication:sanction-policy.title"));
        setShowMessagePage(i18n.t("authentication:sanction-policy.message"));
    }
    if (access === "denied" && criteria === "tiersBlocked") {
        setErrorPage(i18n.t("authentication:blocked-tiers.error"));
        setTitlePage(i18n.t("authentication:blocked-tiers.title"));
        setShowMessagePage(i18n.t("authentication:blocked-tiers.message-error"));
        setShowTitleBtnPage(i18n.t("authentication:blocked-tiers.button"));
    }
    if (access === "denied" && (criteria === "unauthorized" || criteria === "none")) {
        setErrorPage(i18n.t("authentication:unauthorized-tiers.error"));
        setTitlePage(i18n.t("authentication:unauthorized-tiers.title"));
        setShowMessagePage(i18n.t("authentication:unauthorized-tiers.message"));
    }
    if (access === "denied" && criteria === "dueDiligence") {
        setErrorPage(i18n.t("authentication:due-diligence.error"));
        setTitlePage(i18n.t("authentication:due-diligence.title"));
        setShowMessagePage(i18n.t("authentication:due-diligence.message"));
        setShowTitleBtnPage(i18n.t("authentication:due-diligence.button"));
    }
};

export default checkTiersValidity;
