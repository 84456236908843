import React, { useContext, useState } from "react";
import "./connect-as.scss";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import FormElements from "../../../../shared/components/form-elements";
import { AuthContext } from "../../../../context";
import { emailRegex } from "../../../../shared/utils/regex";

import logo from "../../../../shared/assets/images/sage_logo_black.png";
import DisableImpersonate from "../disableImpersonate/disableImpersonate";

/**
 * Impersonation component used to render the impersonate form
 */
const ConnectAs = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { enableImpersonate } = useContext(AuthContext);
    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(false);

    const [disableImpers] = useState(!!localStorage.getItem("x-authorization"));

    /**
     * Check wether the email is valid.
     * @param {*} event
     */
    const onEmailChange = (event) => {
        const currentEmail = event.target.value;
        setEmail(currentEmail);
        if (emailRegex.test(currentEmail)) setValidEmail(true);
        else setValidEmail(false);
    };
    /**
     * Function that enables the impersonation.
     * @param {*} impersonateEmail
     */
    const impersonate = (impersonateEmail) => {
        enableImpersonate(impersonateEmail);
    };
    /**
     * Function used for skipping the impersonation part.
     */
    const skipImpersonate = () => {
        history.push({ pathname: "/loading" });
    };

    return (
        <>
            {disableImpers && <DisableImpersonate />}
            <div className="authentication-form">
                <div className="authentication-form__header">
                    <img src={logo} className="authentication-form__logo" alt={t("title")} />{" "}
                    <div className="authentication-form__title">
                        <p> {t("authentication:connect.connect-as")} </p>
                    </div>
                </div>
                <div className="authentication-form__item">
                    <label className="authentication-form__item-label">{t("authentication:authenticationForm.email")}</label>
                    <FormElements.Input className="authentication-form__input" value={email} onChange={onEmailChange} />
                </div>
                <FormElements.Button
                    type="button"
                    className="button button--large button--primary authentication-form__button"
                    onClick={() => impersonate(email)}
                    disabled={!validEmail}
                >
                    {t("authentication:authenticationForm.button")}
                </FormElements.Button>
                <div>
                    <FormElements.Button type="button" className="button button--small skip-impersonate" onClick={() => skipImpersonate(email)}>
                        {t("authentication:connect.skip")}
                    </FormElements.Button>
                </div>
            </div>
        </>
    );
};

export default ConnectAs;
