import axios from "axios";
import setupInterceptor from "./setup-interceptor";

const { REACT_APP_API_BASE_URL, REACT_APP_API_PROXY_PIVOT } = process.env;

/**
 * Axios instance used to access proxy pivot API
 * @type {AxiosInstance}
 */

const proxyPivotAPI = axios.create({
    baseURL: `${REACT_APP_API_PROXY_PIVOT}`,
    validateStatus: (status) => status < 400
});

/**
 * Axios instance used to access pricer API
 * @type {AxiosInstance}
 */
const pricerAPI = axios.create({
    baseURL: `${REACT_APP_API_BASE_URL}/sbc`,
    validateStatus: (status) => status < 400
});

setupInterceptor(pricerAPI);
setupInterceptor(proxyPivotAPI);

export { proxyPivotAPI, pricerAPI };
