import React, { useState, useEffect, useContext } from "react";
import "./attachment-form.scss";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import jwt from "jsonwebtoken";
import { Base64 } from "js-base64";
import FormElements from "../../../../shared/components/form-elements";
import { emailRegex, phoneNumberRegex, nameRegex } from "../../../../shared/utils/regex";
import Icon from "../../../../shared/components/icon-svg/icon";
import logo from "../../../../shared/assets/images/logo_sage.svg";
import BlockedTiers from "../blocked-tiers/blocked-tiers";
import { attachInterlocutorToCodeTiers } from "../../services/tiers";
import { InterlocContext } from "../../../../context/interlocContext";
import { AuthContext } from "../../../../context";
import { redirectToZendesk } from "../../services/interlocutor";
import { ErrorContext } from "../../../../context/errorContext";
import handleError from "../../../../shared/utils/errorService";
import checkTiersValidity from "../../../../shared/utils/tiers.validator";

import LoadingShared from "../../../../shared/components/loading/loading";

/**
 * Attachment component used to render the attachment form
 */
const AttachmentForm = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { a0User } = useContext(AuthContext);
    const { setErrorPage, setTitlePage, setShowMessagePage, setShowTitleBtnPage } = useContext(ErrorContext);

    const { interlocutor } = useContext(InterlocContext);
    const [firstName, setFirstName] = useState("");
    const [validFirstName, setValidFirstName] = useState(false);
    const [isTiersBlocked, setIsTiersBlocked] = useState(false);
    const [lastName, setLastName] = useState("");
    const [validLastName, setValidLastName] = useState(false);

    const [phone, setPhone] = useState("");
    const [validPhone, setValidPhone] = useState(false);

    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(false);

    const [codeTiers, setCodeTiers] = useState("");
    const [validCodeTiers, setValidCodeTiers] = useState(true);
    const [isNotExistCodeTiers, setIsNotExistCodeTiers] = useState(false);

    const [isFemaleInput, setIsFemaleInput] = useState(false);

    const [isDisabled, setIsDisabled] = useState(false);
    const [isDisabledInterlocutor, setIsDisabledInterlocutor] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (validFirstName && validLastName && validPhone && validEmail && validCodeTiers) {
            setIsDisabled(true);
        } else setIsDisabled(false);
    }, [validFirstName, validLastName, validPhone, validEmail, validCodeTiers]);

    /**
     * Check wether the first name is valid.
     * @param {*} event
     */
    const onFirstNameChange = (event) => {
        const currentFisrtName = event.target.value;
        setFirstName(currentFisrtName);
        if (nameRegex.test(currentFisrtName)) setValidFirstName(true);
        else setValidFirstName(false);
    };
    /**
     * Check whether the last name is valid.
     * @param {*} event
     */
    const onLastNameChange = (event) => {
        const currentLastName = event.target.value;
        setLastName(currentLastName);
        if (nameRegex.test(currentLastName)) setValidLastName(true);
        else setValidLastName(false);
    };
    /**
     * Check whether the phone number is valid.
     * @param {*} event
     */
    const onPhoneChange = (event) => {
        const currentPhone = event.target.value;
        setPhone(currentPhone);
        if (phoneNumberRegex.test(currentPhone)) setValidPhone(true);
        else setValidPhone(false);
    };
    /**
     * Check whether the email is valid.
     * @param {*} event
     */
    const onEmailChange = (event) => {
        const currentEmail = event.target.value;
        setEmail(currentEmail);
        if (emailRegex.test(currentEmail)) setValidEmail(true);
        else setValidEmail(false);
    };
    /**
     * Check whether the first name is valid.
     * @param {*} event
     */
    const isCustomerCodeValid = (code) => {
        let isValid = false;
        if (code && code.length > 0) {
            const regex = new RegExp(/^[0-9]{9}$/);
            const firstNumber = code[0];
            isValid = regex.test(code) && (firstNumber === "1" || firstNumber === "2" || firstNumber === "4");
        }
        return isValid;
    };
    /**
     * Check whether the tiers code is valid.
     * @param {*} event
     */
    const onCodeTiersChange = async (event) => {
        if ((!Number(event.target.value) && event.target.value !== "") || event.target.value.length > 8) {
            return;
        }
        const currentCodeTiers = event.target.value;
        setCodeTiers(currentCodeTiers);
        setIsNotExistCodeTiers(false);
        if (isCustomerCodeValid(currentCodeTiers)) {
            setValidCodeTiers(false);
        } else {
            setValidCodeTiers(true);
            setIsDisabled(true);
        }
    };

    /**
     * Functions that checks if the form is valid.
     */
    const isFormValid = () => {
        let error = false;

        if (firstName.length !== 0 && nameRegex.test(firstName)) setValidFirstName(true);
        else {
            setValidFirstName(false);
            error = true;
        }
        if (lastName.length !== 0 && nameRegex.test(lastName)) setValidLastName(true);
        else {
            setValidLastName(false);
            error = true;
        }
        if (phone.length !== 0 && phoneNumberRegex.test(phone)) setValidPhone(true);
        else {
            setValidPhone(false);
            error = true;
        }
        if (email.length !== 0 && emailRegex.test(email)) setValidEmail(true);
        else {
            setValidEmail(false);
            error = true;
        }
        if (codeTiers.length !== 0 && Number(codeTiers) && codeTiers.length === 8 && !isCustomerCodeValid(codeTiers)) {
            setIsNotExistCodeTiers(false);
            setValidCodeTiers(true);
        } else {
            setValidCodeTiers(false);
            error = true;
        }
        return !error;
    };

    /**
     * Function that attach the interlocutor to the given tiers check its validity and sanction policy
     * and redirect the user to zendesk.
     */
    const handleAttachInterlocutor = async () => {
        if (isFormValid()) {
            let newInterlocutor = {};
            if (Object.keys(interlocutor).length !== 0) {
                newInterlocutor = interlocutor;
            } else {
                newInterlocutor = {
                    firstName,
                    lastName,
                    email,
                    phone,
                    civility: isFemaleInput ? 100000001 : 100000000
                };
            }
            setIsLoading(true);
            const auth0Token = localStorage.getItem("a0-token") ? localStorage.getItem("a0-token") : null;
            attachInterlocutorToCodeTiers(codeTiers, newInterlocutor)
                .then(async (result) => {
                    const { token } = result;
                    const decodedToken = jwt.decode(token);
                    const { tiersPivotId, interlocutorPivotId, tiers: tiersToken } = decodedToken || 0;
                    const { interlocutor: interlocutorToken = {} } = tiersToken || 0;
                    const { lastNameToken = "", firstNameToken = "", civility = "" } = interlocutorToken;
                    setIsDisabled(false);
                    const tokenXauthorization = localStorage.getItem("x-authorization") ? localStorage.getItem("x-authorization") : "";
                    await redirectToZendesk(token, tokenXauthorization).then((res) => {
                        const { token: payload } = res.data;
                        const paramsUrl = {
                            tiers_id: tiersPivotId,
                            token: auth0Token,
                            interlocutor_id: interlocutorPivotId,
                            first_name: firstNameToken,
                            last_name: lastNameToken,
                            civility,
                            tiersCode: codeTiers
                        };
                        const paramsUrlBase64 = Base64.encode(JSON.stringify(paramsUrl));
                        const redirectURL = `${process.env.REACT_APP_ZENDESK_URL}/hc/fr?sbc_token=${paramsUrlBase64}&tokenXauthorization=${tokenXauthorization}`;
                        const encodeURL = encodeURIComponent(redirectURL);
                        window.open(`${process.env.REACT_APP_ZENDESK_URL}/access/jwt?jwt=${payload}&return_to=${encodeURL}`, "_self");
                    });
                })
                .catch(async (err) => {
                    setIsDisabled(false);
                    if (Number(localStorage.getItem("tiers-blocked-count")) === 2) {
                        setIsTiersBlocked(true);
                        localStorage.setItem("tiers-blocked", true);
                        setIsLoading(false);
                    } else if (err.response && err.response.data && (err.response.status === 403 || err.response.status === 404)) {
                        // when tiers is bloqued
                        const countNumber = Number(localStorage.getItem("tiers-blocked-count")) || 0;
                        localStorage.setItem("tiers-blocked-count", countNumber + 1);
                        checkTiersValidity(
                            err.response.data.validityTier.access,
                            err.response.data.validityTier.criteria,
                            setErrorPage,
                            setTitlePage,
                            setShowMessagePage,
                            setShowTitleBtnPage
                        );
                        history.push("/error");
                    } else {
                        const countNumber = Number(localStorage.getItem("tiers-blocked-count")) || 0;
                        localStorage.setItem("tiers-blocked-count", countNumber + 1);
                        await handleError(err);
                        history.push("/error");
                    }
                });
        } else {
            setIsDisabled(true);
        }
    };

    useEffect(() => {
        if (localStorage.getItem("tiers-blocked")) {
            setIsTiersBlocked(true);
        } else if (Object.keys(interlocutor).length !== 0) {
            setFirstName(interlocutor.firstName || "");
            setValidFirstName(true);
            setLastName(interlocutor.lastName || "");
            setValidLastName(true);
            setPhone(interlocutor.telephoneFixe || "");
            setValidPhone(true);
            setEmail(interlocutor.email || "");
            setValidEmail(true);
            setIsFemaleInput(interlocutor.civility === 100000001 || 100000000);
            setIsDisabledInterlocutor(true);
        } else if (a0User) {
            setFirstName(a0User.given_name || "");
            setValidFirstName(true);
            setLastName(a0User.family_name || "");
            setValidLastName(true);
            setPhone(a0User.phone || "");
            setValidPhone(true);
            setEmail(a0User.email || "");
            setValidEmail(true);
            setIsFemaleInput(a0User.civility === 100000001 || 100000000);
        }
    }, [a0User, interlocutor]);

    return (
        <>
            {isLoading ? (
                <LoadingShared />
            ) : (
                <div className="attachment-form">
                    <div className="attachment-form__header">
                        <img src={logo} className="attachment-form__logo" alt={t("title")} />{" "}
                        <div className="attachment-form__title">
                            <p> {t("authentication:authenticationForm.sageConnection")} </p>
                        </div>
                    </div>
                    <hr />

                    {isTiersBlocked ? (
                        <BlockedTiers />
                    ) : (
                        <div>
                            <div className="attachment-form__message">
                                <div>
                                    <p className="rattachement-message1">
                                        {" "}
                                        <Icon name="icon-info" />
                                        {t("authentication:attachmentForm.message1")}
                                    </p>
                                </div>
                                <div>
                                    <p className="rattachement-message2">{t("authentication:attachmentForm.message2")}</p>
                                </div>
                            </div>

                            <div className="attachment-form__middle">
                                <div className="radio-code-margin bottom-margin-radio">
                                    <div className="attachment-form__input">
                                        <div className="details-form__section-row details-form__section-row--inline-radios center-radios">
                                            <label className="details-form__item carbon-form__radio">
                                                <input
                                                    type="radio"
                                                    name="male-radio"
                                                    disabled={isDisabledInterlocutor}
                                                    checked={!isFemaleInput}
                                                    onChange={() => setIsFemaleInput(false)}
                                                />
                                                <div className="carbon-form__radio-icon" />

                                                <span>{t("authentication:attachmentForm.male")}</span>
                                            </label>

                                            <label className="details-form__item details-form__item--radio-inline carbon-form__radio">
                                                <input
                                                    type="radio"
                                                    name="female-radio"
                                                    disabled={isDisabledInterlocutor}
                                                    checked={isFemaleInput}
                                                    onChange={() => setIsFemaleInput(true)}
                                                />
                                                <div className="carbon-form__radio-icon" />

                                                <span>{t("authentication:attachmentForm.female")}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="attachment-form__item">
                                    <div className="attachment-form__input">
                                        <label className="authentication-form__item-label">{t("authentication:attachmentForm.lastName")}</label>
                                        <Icon name="asterisk" />
                                        <FormElements.Input
                                            className={`authentication-form__input ${validLastName ? "" : "is-invalid"} ${
                                                isDisabledInterlocutor ? "disabled" : ""
                                            }`}
                                            value={lastName}
                                            disabled={isDisabledInterlocutor}
                                            onChange={onLastNameChange}
                                        />
                                        {validLastName ? "" : <div className="error-message-form">{t("authentication:attachmentForm.errorLastName")}</div>}
                                    </div>
                                    <div className="attachment-form__input">
                                        <label className="authentication-form__item-label">{t("authentication:attachmentForm.firstName")}</label>
                                        <Icon name="asterisk" />
                                        <FormElements.Input
                                            className={`authentication-form__input ${validFirstName ? "" : "is-invalid"} ${
                                                isDisabledInterlocutor ? "disabled" : ""
                                            }`}
                                            value={firstName}
                                            disabled={isDisabledInterlocutor}
                                            onChange={onFirstNameChange}
                                        />
                                        {validFirstName ? "" : <div className="error-message-form">{t("authentication:attachmentForm.errorFirstName")}</div>}
                                    </div>
                                </div>
                                <div className="attachment-form__item">
                                    <div className="attachment-form__input">
                                        <label className="authentication-form__item-label">{t("authentication:authenticationForm.email")}</label>
                                        <Icon name="asterisk" />
                                        <FormElements.Input
                                            className={`authentication-form__input ${validEmail ? "" : "is-invalid"} ${
                                                isDisabledInterlocutor ? "disabled" : ""
                                            }`}
                                            value={email}
                                            disabled={isDisabledInterlocutor}
                                            onChange={onEmailChange}
                                        />
                                        {validEmail ? "" : <div className="error-message-form">{t("authentication:attachmentForm.errorEmail")}</div>}
                                    </div>
                                    <div className="attachment-form__input">
                                        <label className="authentication-form__item-label">{t("authentication:attachmentForm.telephone")}</label>
                                        <Icon name="asterisk" />
                                        <FormElements.Input
                                            className={`authentication-form__input ${validPhone ? "" : "is-invalid"} ${
                                                isDisabledInterlocutor ? "disabled" : ""
                                            }`}
                                            value={phone}
                                            disabled={isDisabledInterlocutor}
                                            onChange={onPhoneChange}
                                        />
                                        {validPhone ? "" : <div className="error-message-form">{t("authentication:attachmentForm.errorPhone")}</div>}
                                    </div>
                                </div>
                                <div className="radio-code-margin">
                                    <div className="attachment-form__input">
                                        <label className="authentication-form__item-label">{t("authentication:attachmentForm.tiersCode")}</label>
                                        <Icon name="asterisk" />
                                        <Icon name="icon-info" />
                                        <FormElements.Input
                                            className={`authentication-form__input ${validCodeTiers ? "" : "is-invalid"}`}
                                            value={codeTiers}
                                            onChange={onCodeTiersChange}
                                        />
                                        {!validCodeTiers ? <div className="error-message-form">{t("authentication:attachmentForm.errorCodeTiers")}</div> : ""}
                                        {isNotExistCodeTiers ? (
                                            <div className="error-message-form">{t("authentication:attachmentForm.errorNotExistCodeTiers")}</div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>

                            <FormElements.Button
                                type="button"
                                onClick={() => {
                                    handleAttachInterlocutor();
                                }}
                                disabled={!isDisabled}
                                className=" button--large button--primary attachment-form__button "
                            >
                                {" "}
                                {t("authentication:attachmentForm.attach")}{" "}
                            </FormElements.Button>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default AttachmentForm;
