import React from "react";
import Authentication from "./domains/authentication";
import "./App.scss";
import "./config/i18n";

function App() {
    return (
        <div className="App">
            <Authentication.Container> </Authentication.Container>
        </div>
    );
}

export default App;
