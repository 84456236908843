const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const phoneNumberRegex = /^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/;

const nameRegex = /^[a-zA-Z -]{2,70}$/;

const NumbersOnlyRegex = /^[0-9]+$/;

export { emailRegex, phoneNumberRegex, nameRegex, NumbersOnlyRegex };
