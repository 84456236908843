import React from "react";
import { useTranslation } from "react-i18next";
import "./footer.scss";

const Footer = (props) => {
    const { className = "" } = props;
    const { t } = useTranslation();

    return (
        <div className={`main-footer ${className}`}>
            <ul className="main-footer__links">
                <li className="main-footer__link">
                    <a href="https://sageid.online-help.sage.fr/">{t("authentication:footer.help")}</a>
                </li>
                <li className="main-footer__link">
                    <a href="#!">{t("authentication:footer.language")}</a>
                </li>
                <li className="main-footer__link">
                    <a href="https://www.sage.com/fr-fr/informations-legales/protection-vie-privee-cookies/">
                        {t("authentication:footer.confidentiality-policy")}
                    </a>
                </li>
                <li className="main-footer__link">
                    <a href="https://status.sage.com/">{t("authentication:footer.service-state")}</a>
                </li>
            </ul>
        </div>
    );
};

export default Footer;
