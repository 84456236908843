import { proxyPivotAPI } from "../../../config/axios/index";

const { REACT_APP_FAILOVER } = process.env;

/**
 * Get tiers and interlocutor by customer code
 * @param {*} code Given customer code
 * @returns Tier
 */
const getWithInterlocutorByCustomerCode = async (code) => {
    const tiersAndInterlocutorsResponse = await proxyPivotAPI.get(`tiers/${code}/interlocutor`, {
        headers: { "x-backend": REACT_APP_FAILOVER }
    });
    const tiersAndInterlocutors = tiersAndInterlocutorsResponse.data;
    return tiersAndInterlocutors;
};
/**
 * Attach interlocutor to tiers by tiers code
 * @param {*} codeTiers
 * @param {*} newInterlocutor
 */
const attachInterlocutorToCodeTiers = async (codeTiers, newInterlocutor) => {
    const attachmentResponse = await proxyPivotAPI.post("auth/tiers/attachment", {
        headers: { "x-backend": REACT_APP_FAILOVER },
        newInterlocutor,
        codeTiers
    });
    const attachment = attachmentResponse.data;
    return attachment;
};

export { getWithInterlocutorByCustomerCode, attachInterlocutorToCodeTiers };
