import React, { createContext, useState } from "react";

export const InterlocContext = createContext();

const InterlocProvider = ({ children }) => {
    const [interlocutor, setInterlocutor] = useState({});
    const [tier, setTier] = useState({});
    return (
        <InterlocContext.Provider
            value={{
                interlocutor,
                setInterlocutor,
                tier,
                setTier
            }}
        >
            {children}{" "}
        </InterlocContext.Provider>
    );
};

export default InterlocProvider;
