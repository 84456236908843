import React, { useState, createContext, useEffect } from "react";
import { Base64 } from "js-base64";
import { useAuth0 } from "@auth0/auth0-react";
import handleError from "../../shared/utils/errorService";
import connectAs from "../../domains/authentication/services/user.service";

const AuthContext = createContext({});

const AuthProvider = (props) => {
    const { logout: a0Logout, user: a0User, isAuthenticated: a0Auth, error, loginWithRedirect, getIdTokenClaims } = useAuth0();
    const { children } = props;
    const [a0Token, setA0Token] = useState(!!localStorage.getItem("a0-token"));
    const [isImpersonate, setIsImpersonate] = useState(!!localStorage.getItem("x-authorization"));

    useEffect(() => {
        if (a0Auth && a0User) {
            getIdTokenClaims({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                scope: "read:token"
            })
                .then((idToken) => {
                    // eslint-disable-next-line no-underscore-dangle
                    localStorage.setItem("a0-token", idToken.__raw);
                    if (!localStorage.getItem("x-user-email") && !localStorage.getItem("x-user-name")) {
                        localStorage.setItem("x-user-email", a0User["https://sage.com/sci/profiles"][0].email);
                        localStorage.setItem("x-user-name", a0User["https://sage.com/sci/profiles"][0].name);
                    }
                    setA0Token(true);
                })
                .catch(async (err) => {
                    await handleError(err);
                    window.open("/error", "_self");
                });
        } else {
            if (localStorage.getItem("a0-token")) localStorage.removeItem("a0-token");
            if (localStorage.getItem("x-user-email")) localStorage.removeItem("x-user-email");
            if (localStorage.getItem("x-user-name")) localStorage.removeItem("x-user-name");
            setA0Token(false);
        }
    }, [a0Auth, a0User, getIdTokenClaims]);

    const login = (email) => {
        const loginHint = {
            user_reference: email
        };
        const loginHintBase64 = Base64.encode(JSON.stringify(loginHint));

        loginWithRedirect({ login_hint: loginHintBase64, prompt: "login" })
            .then()
            .catch(async (err) => {
                await handleError(err);
                window.open("/error", "_self");
            });
    };

    const logout = () => {
        if (isImpersonate) localStorage.removeItem("x-authorization");
        if (a0Token) localStorage.removeItem("a0-token");
        localStorage.removeItem("x-user-email");
        localStorage.removeItem("x-user-name");
        setA0Token(undefined);
        a0Logout({ returnTo: window.location.origin });
    };

    const enableImpersonate = (email) => {
        connectAs(email).then((res) => {
            const { token } = res.data;
            localStorage.setItem("x-authorization", token);
            setIsImpersonate(true);
            window.open("/loading", "_self");
        });
    };

    const disableImpersonate = () => {
        if (localStorage.getItem("x-authorization")) {
            localStorage.removeItem("x-authorization");
            setIsImpersonate(false);
        }
    };

    return (
        <AuthContext.Provider
            value={{
                login,
                error,
                a0Auth,
                a0User,
                a0Token,
                logout,
                enableImpersonate,
                disableImpersonate,
                isImpersonate
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

const AuthConsumer = AuthContext.Consumer;

export { AuthContext, AuthProvider, AuthConsumer };
