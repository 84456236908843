import React, { useState, useEffect } from "react";
import "./blocked-tiers.scss";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
/**
 *  Component used to render the timer for the blocked tiers
 */
const BlockedTiers = () => {
    const { t } = useTranslation();
    const [countDown, setCountDown] = useState();

    const history = useHistory();
    const [runTimer, setRunTimer] = useState(true);
    /**
     * Used for the timer countdown.
     */
    useEffect(() => {
        let timerId;
        if (runTimer) {
            setCountDown(60 * 5);
            timerId = setInterval(() => {
                setCountDown((c) => c - 1);
            }, 1000);
        } else {
            clearInterval(timerId);
        }

        return () => clearInterval(timerId);
    }, [runTimer]);

    useEffect(() => {
        if (countDown < 0 && runTimer) {
            setRunTimer(false);
            setCountDown(0);
            if (localStorage.getItem("tiers-blocked")) {
                localStorage.removeItem("tiers-blocked");
                localStorage.removeItem("tiers-blocked-count");
            }
            history.push({ pathname: "/loading" });
        }
    }, [countDown, history, runTimer]);

    return (
        <div className="blocked-tiers">
            <div className="blocked-tiers__message">
                <p>{t("authentication:blocked-tiers.message")}</p>
            </div>
            <div>
                <p className="blocked-tiers__message-timer">{t("authentication:blocked-tiers.timerMessage")}</p>
                <div className="blocked-tiers__timer">
                    {String(Math.floor(countDown / 60)).padStart(2, 0)}:{String(countDown % 60).padStart(2, 0)}
                </div>
            </div>
        </div>
    );
};

export default BlockedTiers;
