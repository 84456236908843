import { proxyPivotAPI } from "../../../config/axios/index";

/**
 * Get interlocutors by email
 * @param {*} email Given email
 * @returns Interlocutors
 */
const getByEmail = async () => proxyPivotAPI.get("auth/interlocutors");

/**
 * Get tiers information by tiers's code
 * @param {*} code
 * @returns
 */
const getTiersByCustomerCode = async (code) => proxyPivotAPI.get(`auth/tiers/${code}`);

/**
 * Creates token for redirection
 * @param {*} token
 * @returns
 */
const redirectToZendesk = async (token, tokenXauthorization) =>
    proxyPivotAPI.post("auth/redirect", {
        token,
        tokenXauthorization
    });

export { getByEmail, redirectToZendesk, getTiersByCustomerCode };
