import React, { useContext } from "react";
import "./tiers-element.scss";
import jwt from "jsonwebtoken";
import { Base64 } from "js-base64";
import { useHistory } from "react-router-dom";
import { redirectToZendesk } from "../../services/interlocutor";
import handleError from "../../../../shared/utils/errorService";
import { ErrorContext } from "../../../../context/errorContext";
import checkTiersValidity from "../../../../shared/utils/tiers.validator";

/**
 * Tiers component used to render clickable tiers tile in the list of tiers.
 */
const TiersElement = ({ tiers, className, setIsLoading }) => {
    const auth0Token = localStorage.getItem("a0-token") ? localStorage.getItem("a0-token") : null;
    const { setErrorPage, setTitlePage, setShowMessagePage, setShowTitleBtnPage } = useContext(ErrorContext);
    const history = useHistory();

    /**
     * On click function that redirect the user to the zendesk with the chosen tiers.
     * @param {*} attachmentChoice
     */
    const handleSubmitAttachment = async (attachmentChoice) => {
        setIsLoading(true);
        const { token } = attachmentChoice;
        const decodedToken = jwt.decode(token);
        const { interlocutorPivotId, tiersPivotId, tiers: tiersToken } = decodedToken || 0;
        const { interlocutor = {}, tiers: tiersObj = {} } = tiersToken || 0;
        const { tiersName = "", tiersCode = "" } = tiersObj;
        const { lastName = "", firstName = "", civility = "" } = interlocutor;
        const tokenXauthorization = localStorage.getItem("x-authorization") ? localStorage.getItem("x-authorization") : "";
        await redirectToZendesk(attachmentChoice.token, tokenXauthorization)
            .then((result) => {
                const { token: payload } = result.data;
                const paramsUrl = {
                    tiers_id: tiersPivotId,
                    token: auth0Token,
                    interlocutor_id: interlocutorPivotId,
                    first_name: firstName,
                    last_name: lastName,
                    civility,
                    raison_social: tiersName,
                    tiersCode
                };
                const paramsUrlBase64 = Base64.encode(JSON.stringify(paramsUrl));
                const redirectURL = `${process.env.REACT_APP_ZENDESK_URL}/hc/fr?sbc_token=${paramsUrlBase64}&tokenXauthorization=${tokenXauthorization}`;

                const encodeURL = encodeURIComponent(redirectURL);
                window.open(`${process.env.REACT_APP_ZENDESK_URL}/access/jwt?jwt=${payload}&return_to=${encodeURL}`, "_self");
            })
            .catch(async (err) => {
                if (err.response && err.response.data && err.response.status === 403) {
                    // when tiers is bloqued
                    checkTiersValidity(
                        err.response.data.access,
                        err.response.data.criteria,
                        setErrorPage,
                        setTitlePage,
                        setShowMessagePage,
                        setShowTitleBtnPage
                    );
                    history.push("/error");
                } else if (err.response && err.response.data && err.response.status === 404) {
                    checkTiersValidity("denied", "unauthorized", setErrorPage, setTitlePage, setShowMessagePage, setShowTitleBtnPage);
                    history.push("/error");
                } else {
                    await handleError(err);
                    history.push("/error");
                }
            });
    };

    return (
        <div className={`${className}`} onClick={() => handleSubmitAttachment(tiers)} tabIndex={0} role="button" onKeyDown={() => {}}>
            <div>
                <div className="tiers-element__name">{tiers.tiersName}</div>
                <div className="tiers-element__id">{tiers.tiersCode}</div>
            </div>
        </div>
    );
};

export default TiersElement;
