import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "../../../../app.routes";
import { RouterProvider } from "../../../../context/routerContext";
/**
 * Container component ecompassing the different pages.
 */
const Container = () => (
    <div>
        <Router>
            <RouterProvider>
                <AppRoutes />
            </RouterProvider>
        </Router>
    </div>
);
export default Container;
