import StackTrace from "stacktrace-js";
import UAParser from "ua-parser-js";
import { proxyPivotAPI } from "../../config/axios";

const handleError = (err) => {
    StackTrace.fromError(err).then((stackFrames) => {
        const stringifiedStack = stackFrames.map((sf) => sf.toString());
        const parser = new UAParser();
        const uaResult = parser.getResult();

        const url = err && err.config && err.config.url ? err.config.url : null;

        const error = {
            message: err && err.message ? err.message : "",
            url,
            stackFrames: stringifiedStack,
            ua: uaResult
        };
        proxyPivotAPI.post("sbc/error", error);
    });
};

export default handleError;
